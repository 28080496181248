import * as Types from './../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAppViewerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAppViewerQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', features: Array<string>, onboardingSteps: Array<string>, name?: string | null, id: string, gameTickets: Array<string>, registeredAt: any, startToken?: string | null, tonWallets: Array<string>, webappFirstStartedAt?: any | null, gameStats: { __typename?: 'ViewerGameStats', numberOfGamesPlayed: number, hasPlayedPaidGames: boolean }, wallet: { __typename?: 'Wallet', defaultCurrency: Types.Currency, availableCurrencies: Array<Types.Currency>, tokens: Array<{ __typename?: 'WalletToken', isConvertibleTo: Array<Types.Currency>, balance: { __typename?: 'Money', amount: number, currency: Types.Currency } }>, stakes: Array<{ __typename?: 'StakeDetails', valueClass: Types.StakeValueClass, duration: number, createdAt: any, apy: number, claimAt: any, deposit: { __typename?: 'Money', amount: number, currency: Types.Currency } }>, balance: { __typename?: 'Money', currency: Types.Currency, amount: number }, seasonTicketSummary: { __typename?: 'SeasonTicketSummary', paidCommonGameTickets: number, paidNftGameTickets: number, groupedByLabel: Array<{ __typename?: 'SeasonTicketSummaryByLabel', label: string, numberOfTickets: number }> } } } };


export const GetAppViewerDocument = gql`
    query getAppViewer {
  viewer {
    features
    onboardingSteps
    name
    id
    gameTickets
    registeredAt
    startToken
    tonWallets
    webappFirstStartedAt
    gameStats {
      numberOfGamesPlayed
      hasPlayedPaidGames
    }
    wallet {
      defaultCurrency
      availableCurrencies
      tokens {
        isConvertibleTo
        balance {
          amount
          currency
        }
      }
      stakes {
        valueClass
        duration
        deposit {
          amount
          currency
        }
        createdAt
        apy
        claimAt
      }
      balance {
        currency
        amount
      }
      seasonTicketSummary {
        paidCommonGameTickets
        paidNftGameTickets
        groupedByLabel {
          label
          numberOfTickets
        }
      }
    }
  }
}
    `;

/**
 * __useGetAppViewerQuery__
 *
 * To run a query within a React component, call `useGetAppViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppViewerQuery(baseOptions?: Apollo.QueryHookOptions<GetAppViewerQuery, GetAppViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppViewerQuery, GetAppViewerQueryVariables>(GetAppViewerDocument, options);
      }
export function useGetAppViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppViewerQuery, GetAppViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppViewerQuery, GetAppViewerQueryVariables>(GetAppViewerDocument, options);
        }
export type GetAppViewerQueryHookResult = ReturnType<typeof useGetAppViewerQuery>;
export type GetAppViewerLazyQueryHookResult = ReturnType<typeof useGetAppViewerLazyQuery>;
export type GetAppViewerQueryResult = Apollo.QueryResult<GetAppViewerQuery, GetAppViewerQueryVariables>;