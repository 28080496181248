export enum EAppRoute {
  PVP_GAMES = '/pvp-games',
  LEADERBOARDS = '/leaderboards',
  LEADERBOARDS_LEVELS_PRIZES = '/leaderboards-levels-prizes/:scoringSystemVersion',
  LEADERBOARDS_LEAGUE = `/leaderboards/league`,
  LEADERBOARDS_SEASONS = `/leaderboards/seasons`,
  LEADERBOARDS_CHALLENGE = `/leaderboards/challenge/:challengeId`,
  LEADERBOARDS_CHALLENGE_LEADERS = `/leaderboards/challenge/:challengeId/leaders`,
  LEADERBOARDS_CHALLENGE_LEADER = `/leaderboards/challenge/:challengeId/leaders/:participantId`,
  LEADERBOARDS_CHALLENGE_PRIZES = `/leaderboards/challenge/:challengeId/prizes`,
  LEADERBOARDS_SCORING = `/leaderboards/scoring/:scoringSystemVersion`,
  MY_PVP_GAMES = '/my-pvp-games/:currentOrPast',
  LOOTBOX_INFO = '/loot-box-info',
  DISTRIBUTED_RULE_FILTERED_CARDS = '/distributed-rule-filtered-cards',
  LOOTBOX_PURCHASE = '/lootbox-purchase',
  EURO2024 = '/euro2024',
  TG_STARS = '/tg-stars',
  EURO2024_PLAYOFF = '/euro2024-playoff',
  UNITED_TON_DEPOSIT = '/united-ton-deposit',
  ENTER_AMOUNT = '/enter-amount',
  SELECT_WALLET = '/select-wallet',
  SELECT_WALLET_ADRESS = '/select-wallet-adress', // Leaving it as is in case of possible incoming links with a typo from other sources
  SELECT_WALLET_ADDRESS = '/select-wallet-address',
  WAITING_FOR_TRANSFER = '/waiting-for-transfer',
  WALLET = '/wallet',
  SEND_MONEY = '/send-money',
  SWAP_MONEY = '/swap-money',
  SWAP_MONEY_REQUEST_CREATED = '/swap-money-request-created',
  STAKE_MONEY = '/stake-money',
  STAKE_MONEY_GUIDE = '/stake-money-guide',
  STAKE_MONEY_CREATED = '/stake-money-created',
  STAKE_MONEY_DETAILS = '/stake-money-details',
  WITHDRAWAL_REQUEST_CREATED = '/withdrawal-request-created',
  CONNECT_WALLET = '/connect-wallet',
  WINLINE = '/winline',
  WINLINE_PLAYOFF = '/winline-playoff',
  LISTING_PAGE = '/listing',
}
